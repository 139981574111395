



































import {Component} from 'vue-property-decorator';
import ArticleList from "@/views/Article/ArticleList.vue";

@Component
export default class ArticleListShort extends ArticleList {

  public cleverTrim(text, length) {
    return text.length > length ? text.substring(0, length) + "..." : text;
  }

  public loadArticles(): void {
    this.axios.get(this.settings.settings.host + '/api/articles',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc'
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  getVideoCodeFromLink(link: string): string {
    return link
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('&ab_channel=FundacjaProSpe', '')
  }

}
