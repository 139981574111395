










































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import {Article} from "@/views/Article/Article";

@Component
export default class ArticleList extends Vue {
  public list = [];
  public settings: Settings;
  public actualPage: number;
  public totalItems: number;
  public limit: number;

  public constructor() {
    super();
    this.list = [];
    this.actualPage = 1;
    this.totalItems = 1;
    this.limit = 12;
    this.settings = new Settings();
  }

  public loadArticles(): void {
    this.axios.get(this.settings.settings.host + '/api/articles',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc'
      },
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  public getArticleType(type: number) {
    return Article.getTypeNameById(type);
  }

  public deleteArticle(idx, id): void {
    this.axios.delete(this.settings.settings.host + '/api/articles/' + id,{
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.$notify({
        group: 'notify',
        type: 'success',
        text: 'Artykuł został usuniety'
      });
      this.loadArticles();
    });
  }

  created(): void {
    this.loadArticles();
  }
}
