export class Article {
    public static TYPES = [
        {
            id: 1,
            name: 'Katechezy'
        },
        {
            id: 2,
            name: 'Video blog'
        },
        {
            id: 3,
            name: 'Komentarz niedzielny'
        },
        {
            id: 4,
            name: 'Rekolekcje'
        }
    ];

    public static getTypeNameById(id: number) {
        return this.TYPES.find((type) => type.id === id);
    }
}
